import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaveRoleRequest } from '@app/models/requests/create-role-request';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  constructor(private http: HttpClient) {}

  load(propertyId: number) {
    return this.http.get(`property/${propertyId}/acl/roles`);
  }

  loadPropertiesRoles(
    propertiesIds: number[],
  ): Observable<Array<IResponseSuccess & { propertyId: number }>> {
    return forkJoin(
      propertiesIds.map((propertyId) =>
        (
          this.http.get(
            `property/${propertyId}/acl/roles`,
          ) as Observable<IResponseSuccess>
        ).pipe(map((res) => ({ ...res, propertyId }))),
      ),
    );
  }

  loadDetails(propertyId: number, roleId: number) {
    return this.http.get(`property/${propertyId}/acl/role/${roleId}`);
  }

  loadPresets(property_id: number) {
    return this.http.get(`property/${property_id}/acl/property_role_presets`);
  }

  loadPresetDetails(presetId: number, property_id: number) {
    return this.http.get(
      `property/${property_id}/acl/property_role_preset/${presetId}`,
    );
  }

  create(propertyId: number, roleData: SaveRoleRequest) {
    return this.http.post(`property/${propertyId}/acl/role`, roleData);
  }

  delete(propertyId: number, roleId: number) {
    return this.http.delete(`property/${propertyId}/acl/role/${roleId}`);
  }

  update(propertyId: number, roleData: SaveRoleRequest) {
    return this.http.put(
      `property/${propertyId}/acl/role/${roleData.property_role_id}`,
      roleData,
    );
  }
}

import { Role } from '@app/models/objects/role';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectRoleState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('role');

export const selectAllRoleItems: (
  state: object,
) => Role[] = featureAdapter.getSelectors(selectRoleState).selectAll;

export const selectAllRoleEntities: (
  state: object,
) => { [roleId: number]: Role } = featureAdapter.getSelectors(selectRoleState)
  .selectEntities;

export const selectAllRolesWithMaster = (withMaster: boolean) =>
  createSelector(selectAllRoleItems, (roles) =>
    !withMaster ? roles.filter((role) => !role.is_master) : roles,
  );

export const selectRoleById = (id: string) =>
  createSelector(selectAllRoleItems, (allRole: Role[]) => {
    if (allRole) {
      return allRole.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectRoleError: MemoizedSelector<object, any> = createSelector(
  selectRoleState,
  getError,
);

export const selectRoleIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectRoleState, getIsLoading);

export const selectPropertiesRoles: MemoizedSelector<
  object,
  { [propertyId: number]: Role[] }
> = createSelector(selectRoleState, (state: State) => state.propertiesRoles);

import { Role } from '@app/models/objects/role';
import { SaveRoleRequest } from '@app/models/requests/create-role-request';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[Role] Load Request',
  LOAD_FAILURE = '[Role] Load Failure',
  LOAD_SUCCESS = '[Role] Load Success',

  LOAD_PROPERTIES_ROLES_REQUEST = '[Role] Load Properties Roles Request',
  LOAD_PROPERTIES_ROLES_FAILURE = '[Role] Load Properties Roles Failure',
  LOAD_PROPERTIES_ROLES_SUCCESS = '[Role] Load Properties Roles Success',

  CREATE_REQUEST = '[Role] Create Request',
  CREATE_FAILURE = '[Role] Create Failure',
  CREATE_SUCCESS = '[Role] Create Success',

  DELETE_REQUEST = '[Role] Delete Request',
  DELETE_FAILURE = '[Role] Delete Failure',
  DELETE_SUCCESS = '[Role] Delete Success',

  UPDATE_REQUEST = '[Role] Update Request',
  UPDATE_FAILURE = '[Role] Update Failure',
  UPDATE_SUCCESS = '[Role] Update Success',

  RESET_STATE = '[Role] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyId: number }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Role[] }) {}
}

export class LoadPropertiesRolesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_ROLES_REQUEST;
  constructor(public payload: { propertiesIds: number[] }) {}
}

export class LoadPropertiesRolesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_ROLES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPropertiesRolesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PROPERTIES_ROLES_SUCCESS;
  constructor(public payload: { items: { [propertyId: number]: Role[] } }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      roleData: SaveRoleRequest;
      redirect: boolean;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { item: Role }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertyId: number; roleId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { id: number }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: { propertyId: number; roleData: SaveRoleRequest },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { item: Role }) {}
}
// export class AttachRequestAction implements Action {
//   readonly type = ActionTypes.ATTACH_REQUEST;
//   constructor(
//     public payload: {
//       request: {
//         role_id: number;
//         role_tag_id: number;
//         tagData?: IRoleTag;
//         noUpdate?: boolean;
//       };
//     },
//   ) {}
// }

// export class AttachFailureAction implements Action {
//   readonly type = ActionTypes.ATTACH_FAILURE;
//   constructor(public payload: { error: string }) {}
// }

// export class AttachSuccessAction implements Action {
//   readonly type = ActionTypes.ATTACH_SUCCESS;
// }

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadPropertiesRolesRequestAction
  | LoadPropertiesRolesFailureAction
  | LoadPropertiesRolesSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateFailureAction
  | UpdateSuccessAction
  // | AttachRequestAction
  // | AttachFailureAction
  // | AttachSuccessAction
  | ResetSuccessAction;

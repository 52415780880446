import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { RolesService } from '@app/services/roles-service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class RoleStoreEffects {
  constructor(
    private dataService: RolesService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private _router: Router,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this._errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadPropertiesRolesRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadPropertiesRolesRequestAction>(
        featureActions.ActionTypes.LOAD_PROPERTIES_ROLES_REQUEST,
      ),
      switchMap((action: featureActions.LoadPropertiesRolesRequestAction) =>
        this.dataService.loadPropertiesRoles(action.payload.propertiesIds).pipe(
          map((responses) => {
            return new featureActions.LoadPropertiesRolesSuccessAction({
              items: responses.reduce(
                (propertiesRoles, { data, propertyId }) =>
                  (propertiesRoles = {
                    ...propertiesRoles,
                    [propertyId]: data,
                  }),
                {},
              ),
            });
          }),
          catchError((error) => {
            this._errorHandler.handle(error);
            return of(
              new featureActions.LoadPropertiesRolesFailureAction({ error }),
            );
          }),
        ),
      ),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertyId, roleData, redirect },
        }: featureActions.CreateRequestAction) =>
          this.dataService.create(propertyId, roleData).pipe(
            map(({ data }: any) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.create_success',
                  {
                    param: this._translate.instant('role'),
                  },
                ),
                type: 'success',
              });
              if (redirect) {
                this._router.navigate(['/configuration/users-roles/roles']);
              }
              return new featureActions.CreateSuccessAction({
                item: data[0],
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertyId, roleId },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(propertyId, roleId).pipe(
            map(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('role'),
                  },
                ),
                type: 'success',
              });

              return new featureActions.DeleteSuccessAction({
                id: roleId,
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { propertyId, roleData },
        }: featureActions.UpdateRequestAction) =>
          this.dataService.update(propertyId, roleData).pipe(
            map((response: any) => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('role'),
                  },
                ),
                type: 'success',
              });

              return new featureActions.UpdateSuccessAction({
                item: response.data[0],
              });
            }),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
